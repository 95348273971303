import React from 'react';

import { Layout, HeroGeneric, BlogGrid, SEO } from '../components';

export default function Blog(props) {
  return (
    <Layout {...props}>
      <SEO title="Blog" pathname={props.location.pathname} />
      <HeroGeneric title="Things We Love" />
      <BlogGrid />
    </Layout>
  );
}
